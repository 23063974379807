import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

// get file extension
function getFileExtension(fileName) {
  return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
}

function NewItem() {
  const [formData, setFormData] = useState({
    model: "codewhisperer",
    language: '',
    ide: '',
    plugin_version: '',
    rating: '',
    license: "permissive",
    type_of_problem: [],
    comments: '',
    file1: null,
    file2: null,
    file3: null,
  });

  // In case of error
  const [errors, setErrors] = useState({});

  const formRef = useRef(null);

  // Define an array of keys you want to stringify
  const keysToStringify = ['model', 'ide', 'plugin_version', 'language', 'rating', 'license', 'type_of_problem', 'comments'];

  //Reset values after submission
  const handleReset = () => {
    setFormData({
      model: "codewhisperer",
      ide: '',
      plugin_version: '',
      language: '',
      rating: '',
      license: "permissive",
      type_of_problem: [],
      comments: '',
      file1: null,
      file2: null,
      file3: null,
    });
    // Reset the file input by resetting the form
    formRef.current.reset();
  }

  // Upload files to a folder in S3
  async function uploadFiles() {

    try {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
      const day = currentDate.getDate();
      const uuid = uuidv4();
      const folderName = month + "/" + day + "/" + uuid + "/";

      // Create a new object containing only the selected keys
      const selectedData = {};
      for (const key of keysToStringify) {
        if (formData.hasOwnProperty(key)) {
          selectedData[key] = formData[key];
        }
      }

      const file1 = await Storage.put('examples/' + folderName + "pre_generation." + getFileExtension(formData.file1.name), formData.file1);
      const file2 = await Storage.put('examples/' + folderName + "post_generation." + getFileExtension(formData.file2.name), formData.file2);
      if (formData.file3 !== null) {
        const file3 = await Storage.put('examples/' + folderName + "expected_results." + getFileExtension(formData.file3.name), formData.file3);
      }
      const file4 = await Storage.put('examples/' + folderName + "overview.json", JSON.stringify(selectedData, null, 2));

      // Trigger an alert when a checkbox state changes
      handleReset();
      alert(`Files have been uploaded correctly`);

    } catch (error) {
      const validationErrors = {};
      validationErrors.name = 'An error has ocurred, please try later';
      setErrors(validationErrors);
      console.error('Error uploading files:', error);
    }
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    let updatedTypeOfProblem = [...formData.type_of_problem]; // Create a copy of the existing array

    if (checked) {
      // Add the value to the array if the checkbox is checked
      updatedTypeOfProblem.push(value);
    } else {
      // Remove the value from the array if the checkbox is unchecked
      updatedTypeOfProblem = updatedTypeOfProblem.filter((item) => item !== value);
    }

    // Update the formData state with the updated array
    setFormData({ ...formData, type_of_problem: updatedTypeOfProblem });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};

    // Check if language is empty
    if (!formData.language) {
      validationErrors.name = 'language is required';
    }
    // Check if ide is empty
    if (!formData.ide) {
      validationErrors.name = 'ide is required';
    }
    // Check if failure is empty
    if (!formData.type_of_problem.length) {
      validationErrors.name = 'failure is required';
    }
    // Check if rating is empty
    if (!formData.rating) {
      validationErrors.name = 'rating is required';
    }
    // Check if file1 is empty
    if (!formData.file1) {
      validationErrors.name = 'Pre generation file is required';
    }
    // Check if file2 is empty
    if (!formData.file2) {
      validationErrors.name = 'Post generation file is required';
    }

    if (Object.keys(validationErrors).length === 0) {
      // All fields are filled, submit the form
      // You can perform your form submission logic here
      uploadFiles();
      setErrors({});
    } else {
      // Update the errors state with validation errors
      setErrors(validationErrors);
    }
  };

  return (
    <div className="mx-auto" style={{ maxWidth: '800px' }}>
      <h1>Submit CodeWhisperer Code Examples</h1>

      <p>To submit your examples to the CodeWhisperer Applied Science team, please fill out the form below. We appreciate your contribution and thank you for providing examples to the team for service improvment.</p>
      <p>Thank you!</p>
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  {errors.name && <div className="alert alert-danger" role="alert">{errors.name}</div>}
                  <Form.Group>
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      as="select"
                      name="language"
                      value={formData.language}
                      onChange={handleChange}
                      required>
                      <option >Choose an option</option>
                      <option value="Python">Python</option>
                      <option value="Java">Java</option>
                      <option value="JavaScript">JavaScript</option>
                      <option value="TypeScript">TypeScript</option>
                      <option value="C#">C#</option>
                      <option value="Ruby">Ruby</option>
                      <option value="Go">Go</option>
                      <option value="PHP">PHP</option>
                      <option value="C++">C++</option>
                      <option value="C">C</option>
                      <option value="Shell">Shell</option>
                      <option value="Scala">Scala</option>
                      <option value="Rust">Rust</option>
                      <option value="Kotlin">Kotlin</option>
                      <option value="SQL">SQL</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>IDE</Form.Label>
                    <Form.Control
                      as="select"
                      name="ide"
                      value={formData.ide}
                      onChange={handleChange}
                      required>
                      <option >Choose an option</option>
                      <option value="Visual Studio Code">Visual Studio Code</option>
                      <option value="Eclipse">Eclipse</option>
                      <option value="Sagemaker Studio">Sagemaker Studio</option>
                      <option value="JupyterLab">JupyterLab</option>
                      <option value="JetBrains">JetBrains</option>
                      <option value="Cloud9">Cloud9</option>
                      <option value="Lambda">Lambda</option>
                      <option value="Glue Studio">Glue Studio</option>
                    </Form.Control>
                  </Form.Group>
                  {/* Form.Group for plugin version as input */}
                  <Form.Group>
                    <Form.Label>Plugin Version</Form.Label>
                    <Form.Control
                      type="text"
                      name="plugin_version"
                      value={formData.plugin_version}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Failure</Form.Label>
                    {['Hallucination', 'Not aligned', 'Bad formatting'].map((option) => (
                      <Form.Check
                        type="checkbox"
                        label={option}
                        key={option}
                        value={option}
                        onChange={handleCheckboxChange}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group>
                    <Form.FloatingLabel>Rating</Form.FloatingLabel>
                    <Form.Control
                      as="select"
                      name="rating"
                      value={formData.rating}
                      onChange={handleChange}
                      required>
                      <option >Choose an option</option>
                      <option value="Bad">Bad</option>
                      <option value="Medium">Medium</option>
                      <option value="Good">Good</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="comments"
                      value={formData.comments}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Pre Generation File</Form.Label>
                    <Form.Control
                      type="file"
                      name="file1"

                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Post Generation File</Form.Label>
                    <Form.Control
                      type="file"
                      name="file2"

                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>File with Expected Results</Form.Label>
                    <Form.Control
                      type="file"
                      name="file3"

                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Button variant="primary" size="lg" className="mt-3" style={{ width: '100%' }} onClick={handleSubmit}>
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >)
}

export default NewItem;
