/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:fed7fec1-2a66-437c-ad33-dfaa635d0317",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_immFKIU89",
    "aws_user_pools_web_client_id": "noc2t5upr2o3mmqmmghh1vioj",
    "oauth": {
        "domain": "codewhispererfeedback-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://codewhispererfeedback.wwso.aws.dev/",
        "redirectSignOut": "https://codewhispererfeedback.wwso.aws.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "codewhispererfeedbac18824f82099246ff86176b98a3d154357-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
