import { Auth, Hub } from 'aws-amplify'
import React, { useEffect,useState } from 'react';
import NewItem from './components/NewItem';
import { AppLayout,Button, Grid, Box, Spinner } from '@aws-amplify/ui-react';

const FEDERATED_IDENTITY_PROVIDER = 'AmazonFederate';

const App = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Hub.listen('auth', ({payload: {event, data}}) => {
      console.log('A new auth data has happened: ', data)
      console.log('A new auth event has happened: ', event)
      setIsLoading(true);
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => {
            setUser(userData);
            setIsLoading(false);
          });
          break;
        case 'signOut':
          setUser(null);
          setIsLoading(false);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          setIsLoading(false);
          break;
        default:
          setUser(null);
          setIsLoading(false);
      }
    });
console.log("user",user)
    getUser().then((userData) => setUser(userData));
  
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => Auth.signOut());
  }


  if(user) {
    return (
        <NewItem/>
        );
  } else if (isLoading) {
    return (
      <div>Loading...</div>
    )
  } else {
    return(
  <div className="awsui">

            <h1 color="#ff9912;">CodeWhisperer Feedback App</h1>
            <Button
              variant="primary"
              onClick={() =>
                Auth.federatedSignIn({ customProvider: "AmazonFederate" })
              }
            >
              Login With Amazon Midway
            </Button>

        </div>
    );
  }
};

export default App;
